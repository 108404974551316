<template>
  <div id="app">
    <div v-if="backendNotAccessible" class="alert alert-danger" role="alert">
      <strong>Erreur :</strong> Impossible de se connecter au serveur. Veuillez réessayer plus tard.
    </div>
    <router-view v-else-if="me" />
    <SplashScreen v-else class="splashscreen" :class="{ 'visible': !hideSplashScreen }" />
    <LoginModal ref="loginModal" @loginSucceed="loginSucceed" />
    <NotificationList v-if="hasNotifications" />
  </div>
</template>

<script setup lang="ts">

import { ref, computed, watch, onMounted } from "vue";
import { api } from "@/api";
import SplashScreen from "@/views/SplashScreen.vue";
import LoginModal from "@/components/login/LoginModal.vue";
import NotificationList from "@/components/notification/NotificationList.vue";
import { useActivitiesStore } from "@/stores/activities";
import { useJournalsStore } from "@/stores/journals";
import { useOriginsStore } from "@/stores/origins";
import { useTechniciansStore } from "@/stores/technicians";
import { useMeStore } from "@/stores/me";
import { useNotificationsStore } from "@/stores/notifications";
import { useStoragesStore } from "@/stores/storages";
import { useInterventionListStore } from "@/stores/interventionList";
import { useSaleListStore } from "@/stores/saleList";
import { useInterventionReportOptionsStore } from "@/stores/interventionReportOptions";
import { useInterventionSnoozeReasonChoicesStore } from "@/stores/interventionSnoozeReasonChoices";

// Stores

const activitiesStore = useActivitiesStore();
const journalsStore = useJournalsStore();
const originsStore = useOriginsStore();
const techniciansStore = useTechniciansStore();
const meStore = useMeStore();
const notificationsStore = useNotificationsStore();
const storagesStore = useStoragesStore();
const interventionListStore = useInterventionListStore();
const saleListStore = useSaleListStore();
const interventionReportOptionsStore = useInterventionReportOptionsStore();
const interventionSnoozeReasonChoicesStore = useInterventionSnoozeReasonChoicesStore();

// DOM elements

const loginModal = ref(null);

// Data

const hideSplashScreen = ref(false);
const backendNotAccessible = ref(false);

// Computed

const me = computed(() => meStore.getMe);
const isLoggedIn = computed(() => meStore.isLoggedIn);
const hasNotifications = computed(() => notificationsStore.getNotifications.length > 0);

// Methods

const loginSucceed = () => {
  if (loginModal.value) {
    loginModal.value.hide();
    hideSplashScreen.value = false;
  }
  
  loadData();
};

const loadData = async () => {
  meStore.fetchMe()
    .then(() => {
      return Promise.all([
        loadMinimalData(),
      ]);
    })
    .catch((error) => {
      backendNotAccessible.value = true;
      throw error;
    });
};

const loadMinimalData = async () => {
  const response = await api.get("/minimal-data/");

  activitiesStore.setActivities(response.data.activities);
  journalsStore.setJournals(response.data.journals);
  techniciansStore.setTechnicians(response.data.technicians);
  storagesStore.setStorages(response.data.storages);
  interventionListStore.setTempNbInterventionTodo(response.data.interventions_todo_nb);
  saleListStore.setTempNbSaleToShip(response.data.sales_to_ship_nb);
  interventionReportOptionsStore.setActions(response.data.actions);
  interventionReportOptionsStore.setImprovementNeeds(response.data.improvement_needs);
  interventionReportOptionsStore.setSafetyRules(response.data.safety_rules);
  interventionReportOptionsStore.setRooms(response.data.rooms);
  interventionSnoozeReasonChoicesStore.set(response.data.intervention_snooze_reason_choices);

  const origins: any[] = [];

  for (const journal of response.data.journals) {
    if (journal.origin && !origins.some((o) => o.code === journal.origin.code)) {
      origins.push(journal.origin);
    }
  }

  originsStore.setOrigins(origins);
};

// Watchers

watch(isLoggedIn, (newVal) => {
  if (!newVal) {
    loginModal.value.show();
    hideSplashScreen.value = true;
  }
});

// Mounted

onMounted(() => {
  meStore.loadTokensFromLocalStorage();
  if (isLoggedIn.value) {
    loadData();
  } else {
    loginModal.value.show();
    hideSplashScreen.value = true;
  }
});

</script>

<style lang="scss" scoped>

.splashscreen {
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }

  &:not(.visible) {
    opacity: 0;
  }
}


</style>

