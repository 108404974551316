<template>
  <ObjectViewLayout
    :title="title"
    :loading="object == null"
    :errorStr="errorStr"
    withBackButton
    @back="$router.push({ name: 'intervention-list' })">
    <template #title-icon-left>
      <BriefcaseIcon />
    </template>

    <template #title-icon-right>
      <div v-if="object.cancelled" class="highlighted red">
        Annulée
      </div>
    </template>

    <template #rightButton>
      <InterventionToolButtons v-model="object" @showQrCode="showImage" />
    </template>

    <div class="grid">
      <div class="card">
        <div class="card-header">
          <span class="title">Information sur l'intervention</span>
        </div>
        <div class="card-body">
          <div class="info-grid-layout">
            <div>Status</div>
            <div>{{ object.status_type }}</div>
            <div>Origine</div>
            <div class="flow-spaced-container">
              <div>{{ object.origin.code }} ({{ object.origin.tax_rate }}% de taxes)</div>
              <button
                class="small-tool-btn"
                @click="$refs.editOriginModal.show(object)">
                <i class="bi bi-pencil-square" />
              </button>
            </div>
            <div>Type de nuisibles</div>
            <div v-if="objectActivitiesHumanized" class="flow-spaced-container">
              <div>{{ objectActivitiesHumanized }}</div>  
              <button
                class="small-tool-btn"
                @click="$refs.editActivitiesModal.show(object)">
                <i class="bi bi-pencil-square" />
              </button>
            </div>
            <div v-else class="flow-spaced-container">
              <div><i>Aucun</i></div>
              <button
                class="small-tool-btn"
                @click="$refs.editActivitiesModal.show(object)">
                <i class="bi bi-pencil-square" />
              </button>
            </div>
            <div>Demande du client</div>
            <div v-if="object.request">
              {{ object.request }}
            </div>
            <div v-else>
              <i>Aucun</i>
            </div>
            <div>Urgence</div>
            <div v-if="object.is_emergency">
              Oui
            </div>
            <div v-else>
              Non
            </div>
            <div>Remarques à l'attention du technicien</div>
            <div class="flow-spaced-container">
              <div v-if="object.remarks" style="margin-right: 5px;">
                {{ object.remarks }}
              </div>
              <div v-else style="margin-right: 5px;">
                <i>Aucune</i>
              </div>
              <button
                class="small-tool-btn"
                @click="$refs.editRemarksModal.show(object)">
                <i class="bi bi-pencil-square" />
              </button>
            </div>
          </div>
          <EditInterventionActivitiesModal ref="editActivitiesModal" @change="object = {...object, ...$event}" />
          <EditInterventionOriginModal ref="editOriginModal" @change="object = {...object, ...$event}" />
          <EditInterventionRemarksModal ref="editRemarksModal" @change="object = {...object, ...$event}" />
        </div>
      </div>

      <InterventionTechniciansCard
        :intervention="object"
        @updateTechnician="(technician) => object.technician = technician"
        @updateOtherTechnicians="(otherTechnicians) => object.other_technicians = otherTechnicians" />
    </div>

    <div class="grid">
      <InterventionCustomerCard
        v-model="object" />

      <PersonsCard v-model="object.contacts" />

      <LocationCard
        v-model="object.location"
        editable
        title="Adresse d'intervention" />
    </div>

    <div class="grid">
      <InterventionInvoiceCard
        v-model="object.invoice"
        :interventionId="object.id" />

      <InterventionQuoteCard
        v-model="object.quote"
        :interventionId="object.id" />
    </div>

    <template #rightBar>
      <div class="vertical-spaced-container">
        <div
          :class="{ 'yellow': object.rdv_date == null, 'green': object.rdv_date != null }"
          class="card clickable"
          @click="$refs.editInterventionRdvModal.show(object)">            
          <div class="card-body">
            <div class="info-grid-layout">
              <div>
                Rendez-vous
              </div>
              <div>
                <div class="flow-spaced-container">
                  <div v-if="object.rdv_date">
                    {{ $filters.dateStringToHumanizedDateTime(object.rdv_date) }}
                  </div>
                  <div v-else>
                    Non
                  </div>
                </div>
              </div>
              <div>
                Fourchette d'arrivée du tech
              </div>
              <div>
                <div class="flow-spaced-container">
                  <div v-if="object.rdv_min_start_time && object.rdv_max_start_time">
                    {{ object.rdv_min_start_time.substring(0, 5) }} - {{ object.rdv_max_start_time.substring(0, 5) }}
                  </div>
                  <div v-else>
                    Non
                  </div>
                </div>
              </div>
              <div>
                Durée de l'intervention
              </div>

              <div>
                {{ object.duration }} min
              </div>
            </div>

            <button
              class="tool-btn"
              @click="$refs.editInterventionRdvModal.show(object)">
              <i class="bi bi-pencil-square" />
            </button>
          </div>
        </div>

        <CreatedUpdatedAtCard :object="object" />

        <div class="card">
          <div class="card-body">
            <div class="info-grid-layout">
              <div>
                Numéro d'intervention
              </div>
              <div>
                {{ object.ref_number }}
              </div>
              <div>
                Numéro de communication structurée
              </div>
              <div>
                {{ object.structured_communication }}
              </div>
              <div>
                Premier rendez-vous
              </div>

              <div>
                <span v-if="object.first_rdv">Oui</span><span v-else>Non</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <p>
              <a :href="`https://prod.edn.ovh/interventions/${object.id}/suivi/`" target="_blank" class="btn-link">
                Page de suivi de l'intervention <i class="bi bi-box-arrow-up-right" />
              </a>
            </p>
            <p>
              <a class="btn-link" :href="`https://prod.edn.ovh/interventions/${object.id}/email-de-notification-intervention-cree-envoye-au-client/`" target="_blank">
                Email de notification d'intervention créée (envoyé au client) <i class="bi bi-box-arrow-up-right" />
              </a>
            </p>
            <p>
              <a class="btn-link" :href="`https://prod.edn.ovh/interventions/${object.id}/email-de-notification-de-rdv-envoye-au-client/`" target="_blank">
                Email de notification de rendez-vous fixé (envoyé au client) <i class="bi bi-box-arrow-up-right" />
              </a>
            </p>
            <p>
              <a class="btn-link" :href="`https://prod.edn.ovh/interventions/${object.id}/email-de-notification-de-rdv-modifie-envoye-au-client/`" target="_blank">
                Email de notification de rendez-vous modifié (envoyé au client) <i class="bi bi-box-arrow-up-right" />
              </a>
            </p>
            <p>
              <a class="btn-link" :href="`https://prod.edn.ovh/interventions/${object.id}/email-de-rappel-avant-intervention-envoye-au-client/`" target="_blank">
                Email de rappel avant intervention (envoyé au client) <i class="bi bi-box-arrow-up-right" />
              </a>
            </p>
          </div>
        </div>

        <InterventionCalendarEventsCard v-model="object" />

        <InterventionTasksCard
          v-model="object" />

        <CallListCard
          :customerId="object.customer.id"
          :calls="object.customer.calls" />

        <HistoryCard
          objectName="l'intervention"
          :history="object.history"
          :postComment="postComment" />

        <EditInterventionRdvModal
          ref="editInterventionRdvModal"
          @change="object = {...object, ...$event}" />
      </div>
    </template>
  </ObjectViewLayout>

  <ImageModal ref="imageModal" @click.stop />
</template>

<script>

import ObjectViewLayout from "@/components/layout/ObjectViewLayout.vue";
import InterventionToolButtons from "@/components/toolbuttons/InterventionToolButtons.vue";
import BriefcaseIcon from "@/assets/iconsVue/BriefcaseIcon.vue";
import InterventionInvoiceCard from "@/components/cards/InterventionInvoiceCard.vue";
import InterventionQuoteCard from "@/components/cards/InterventionQuoteCard.vue";
import InterventionTechniciansCard from "@/components/cards/InterventionTechniciansCard.vue";
import PersonsCard from "@/components/cards/PersonsCard.vue";

import InterventionCustomerCard from "@/components/cards/customerCards/InterventionCustomerCard.vue";
import LocationCard from "@/components/cards/LocationCard.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";

import CallListCard from "@/components/CallListCard.vue";
import InterventionTasksCard from "@/components/cards/interventionCards/InterventionTasksCard.vue";
import InterventionCalendarEventsCard from "@/components/cards/interventionCards/InterventionCalendarEventsCard.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CreatedUpdatedAtCard from "@/components/cards/generic/CreatedUpdatedAtCard.vue";

import EditInterventionActivitiesModal from "@/components/modals/EditInterventionActivitiesModal.vue";
import EditInterventionOriginModal from "@/components/modals/EditInterventionOriginModal.vue";
import EditInterventionRemarksModal from "@/components/modals/EditInterventionRemarksModal.vue";
import EditInterventionRdvModal from "@/components/modals/EditInterventionRdvModal.vue";

export default {
  name: "InterventionObjectView",
  components: {
    ObjectViewLayout,
    InterventionToolButtons,
    EditInterventionRemarksModal,
    BriefcaseIcon,
    InterventionInvoiceCard,
    InterventionTechniciansCard,
    InterventionCustomerCard,
    InterventionQuoteCard,
    InterventionTasksCard,
    CallListCard,
    InterventionCalendarEventsCard,
    ImageModal,
    CreatedUpdatedAtCard,
    LocationCard,
    PersonsCard,
    EditInterventionActivitiesModal,
    EditInterventionOriginModal,
    HistoryCard,
    EditInterventionRdvModal,
  },
  data() {
    return {
      object: null,
      schema: null,
      errorStr: null,
    };
  },
  computed: {
    objectActivitiesHumanized() {
      if (this.object.activities) {
        const activities = [];
        
        for (const activity of this.object.activities) {
          activities.push(activity.name);
        }
        return activities.join(", ");
      }
      return null;
    },
    title() {
      if (!this.object) {
        return "Chargement de l'intervention...";
      } else {
        return "Intervention " + this.object.ref_number;
      }
    },
  },
  mounted() {
    Promise.all([
      this.fetchObject(),
      this.fetchSchema(),
    ]).catch((err) => {
      this.errorStr = "Une erreur est survenue lors du chargement de l'intervention";
      throw err;
    });
  },
  methods: {
    fetchObject() {
      return this.$services.intervention.retrieve(this.$route.params.id)
        .then((response) => {
          this.object = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.errorStr = "L'intervention n'existe pas ou vous n'avez pas les droits pour la consulter. Contactez le Webmaster si vous pensez qu'il s'agit d'une erreur.";
          } else {
            throw error;
          }
        });
    },
    fetchSchema() {
      return this.$services.intervention.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.object.customer.id }});
    },

    onCustomEvent(description) {
      this.$services.intervention.createEvent(this.object.id, description)
        .then((response) => {
          this.object.events = response.data.events;
          this.$refs.eventListCard.reset();
        });
    },
    postComment(comment) {
      return this.$services.intervention.addComment(this.object.id, comment)
        .then((response) => {
          this.object.history = response.data.history;
        });
    },

    showImage(image) {
      this.$refs.imageModal.show(image);
    },
    
  },
};
</script>


<style lang="scss" scoped>

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-flow: row;
  margin-bottom: 10px;
}

</style>