<template>
  <button
    class="tool-btn tooltip"
    @click.stop="$refs.addTaskModal.show()">
    <i class="bi bi-pause-circle" />
    <div class="tooltip-text">
      Mettre en attente l'intervention
    </div>
  </button>
  
  <button
    class="tool-btn tooltip"
    @click="gotoCustomer">
    <i class="bi bi-person" />
    <div class="tooltip-text">
      Client
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null"
    @click.stop="$refs.sendEmailModal.show()">
    <i class="bi bi-envelope-at" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Envoyer un email au client
      </div>
      <div v-if="modelValue.contact?.email == null && modelValue.customer?.contact?.email == null" class="tooltip-text-red">
        Aucun email associé au client
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="modelValue.rdv_date != null"
    @click.stop="makeAppointment">
    <i class="bi bi-calendar-event" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Plannifier un rendez-vous
      </div>
      <div v-if="modelValue.rdv_date != null" class="tooltip-text-red">
        Déjà plannifié
      </div>
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    :disabled="!canCancel"
    @click.stop="askForCancellationRemarks">
    <i class="bi bi-x-circle" />
    <div class="tooltip-container">
      <div class="tooltip-text">
        Annuler l'intervention
      </div>
      <div v-if="modelValue.cancelled" class="tooltip-text-red">
        Déjà annulée
      </div>
      <div v-if="modelValue.stop_instant != null" class="tooltip-text-red">
        Déjà terminée
      </div>
    </div>
  </button>

  <button
    v-if="modelValue.quote"
    class="tool-btn tooltip"
    @click.stop="gotoQuote">
    <InvoiceIcon status="quote" />
    <div class="tooltip-text">
      Voir le devis
    </div>
  </button>

  <button
    v-else
    class="tool-btn tooltip"
    :disabled="modelValue.invoice"
    @click.stop="createQuote">
    <InvoiceIcon status="quote" />
    <div class="tooltip-text">
      Nouveau devis
      <span v-if="modelValue.invoice" class="text-red">(L'intervention semble déjà facturée)</span>
    </div>
  </button>

  <button
    v-if="modelValue.invoice"
    class="tool-btn tooltip"
    @click.stop="gotoInvoice">
    <InvoiceIcon status="invoice" />
    <div class="tooltip-text">
      Voir la facture
    </div>
  </button>

  <button
    v-else
    class="tool-btn tooltip"
    @click.stop="createInvoice">
    <InvoiceIcon status="invoice" />
    <div class="tooltip-text">
      Nouvelle facture
    </div>
  </button>

  <button class="tool-btn tooltip" @click.stop="gotoReport">
    <i class="bi bi-file-earmark" />
    <div class="tooltip-text">
      Voir le rapport d'intervention
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="copyIntervention">
    <i class="bi bi-briefcase" />
    <div class="tooltip-text">
      Copier l'intervention
    </div>
  </button>

  <button
    class="tool-btn tooltip"
    @click.stop="showQrCode">
    <Loader v-if="fetchingQrCode" />
    <i v-else class="bi bi-qr-code" />
    <div class="tooltip-text">
      QrCode
    </div>
  </button>

  <ImageModal ref="qrCodeModal" @click.stop />

  <InputModal
    ref="cancellationRemarksInputModal"
    label="Quelle est la raison de cette annulation ?"
    :loading="cancellingIntervention"
    @click.stop
    @submit="cancelIntervention" />

  <SendEmailToCustomerModal v-if="modelValue" ref="sendEmailModal" :customer="modelValue" @click.stop />

  <AddTaskToInterventionModal
    ref="addTaskModal"
    v-model="internalValue"
    @click.stop />
</template>

<script>

import SendEmailToCustomerModal from "@/components/modals/SendEmailToCustomerModal.vue";
import Loader from "@/components/Loader.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import InputModal from "@/components/modals/InputModal.vue";
import InvoiceIcon from "@/assets/iconsVue/InvoiceIcon.vue";
import AddTaskToInterventionModal from "@/components/modals/AddTaskToInterventionModal.vue";

export default {
  name: "InterventionToolButtons",
  components: {
    InvoiceIcon,
    SendEmailToCustomerModal,
    Loader,
    ImageModal,
    InputModal,
    AddTaskToInterventionModal,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "showQrCode"],
  data() {
    return {
      fetchingQrCode: false,
      cancellingIntervention: false,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    canCancel() {
      return !this.modelValue.cancelled && this.modelValue.stop_instant == null;
    },
  },
  methods: {
    gotoCustomer() {
      this.$router.push({ name: "customer", params: { id: this.modelValue.customer.id } });
    },
    gotoQuote() {
      this.$router.push({ name: "invoice", params: { id: this.modelValue.quote.id } });
    },
    gotoInvoice() {
      this.$router.push({ name: "invoice", params: { id: this.modelValue.invoice.id } });
    },
    createQuote() {
      this.$router.push({ name: "funnel.intervention.quote", params: { customerId: this.modelValue.customer.id, interventionId: this.modelValue.id } });
    },
    createInvoice() {
      this.$router.push({ name: "funnel.intervention.invoice", params: { customerId: this.modelValue.customer.id, interventionId: this.modelValue.id } });
    },
    makeAppointment() {
      this.$router.push({ name: "intervention-make-appointment", params: { id: this.modelValue.id } });
    },
    copyIntervention() {
      this.$router.push({ name: "funnel.customer", params: { mode: "intervention"}, query: { interventionToCopyId: this.modelValue.id } });
    },
    askForCancellationRemarks() {
      this.$refs.cancellationRemarksInputModal.show();
    },
    cancelIntervention(remarks) {
      this.cancellingIntervention = true;
      
      this.$services.intervention.cancel(this.modelValue.id, remarks)
        .then((response) => {
          this.internalValue = response.data;
          this.syncInterventionWithGoogleCalendar();
          this.$refs.cancellationRemarksInputModal.hide();
        }).finally(() => {
          this.cancellingIntervention = false;
        });
    },
    syncInterventionWithGoogleCalendar() {
      this.$services.intervention.syncWithGoogleCalendar(this.modelValue.id);
    },
    gotoReport() {
      this.$router.push({ name: "intervention-report", params: { id: this.modelValue.id } });
    },
    showQrCode() {
      this.fetchingQrCode = true;

      this.$services.intervention.fetchQrCode(this.modelValue.id)
        .then((response) => {
          const qrCodeUrl = "data:image/png;base64,"+ response.data;
          this.$emit("showQrCode", qrCodeUrl);
        }).finally(() => {
          this.fetchingQrCode = false;
        });
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>