<template>
  <Modal ref="modal" width="medium">
    <div v-if="event">
      <div style="margin-bottom: 20px;">
        <strong v-if="event.cancelled">[ANNULE]</strong>
        <strong v-if="event.type == 'intervention'">Intervention</strong>
        <strong v-else>{{ event.summary }}</strong>
      </div>
      
      <div class="info-grid-layout form-group">
        <div>Utilisateur</div>
        <div>{{ $filters.fullname(event.user) }}</div>
        <div>Lieu</div>
        <div>{{ event.location }}</div>
        <div>Description</div>
        <div>{{ event.description }}</div>
      </div>

      <div v-if="event.intervention" class="info-grid-layout form-group">
        <div>Numéro d'intervention</div>
        <div>{{ event.intervention.ref_number }}</div>
        <div>Numéro de communication structurée</div>
        <div>{{ event.intervention.structured_communication }}</div>
        <div>Premier rendez-vous</div>
        <div v-if="event.intervention.first_rdv">
          Oui
        </div><div v-else>
          Non
        </div>
      </div>

      <div v-else class="info-grid-layout form-group">
        <div>Début</div>
        <div>{{ $filters.dateStringToHumanizedDate(event.start_date) }} {{ event.start_time }}</div>
        <div>Fin</div>
        <div>{{ $filters.dateStringToHumanizedDate(event.end_date) }} {{ event.end_time }}</div>
      </div>
          
      <div v-if="event.intervention" class="form-group">
        <label class="form-label">Date de rendez-vous</label>
        <div class="tooltip">
          <RdvDatePicker
            v-model="event.intervention.rdv_date"
            @update:modelValue="onRdvDateSelected" />
        </div>

        <div
          v-if="synchronizingWithGoogleCalendar"
          style="display: flex; align-items: center; margin-bottom: 10px; color: blue;">
          <div style="margin-right: 5px;">
            <Loader />
          </div>
          Synchronisation Google Agenda en cours...
        </div>
      </div>

      <div v-if="event.intervention" class="row">
        <div class="col-md form-group">
          <label class="form-label">Le technicien peut arriver à partir de</label>
          <div style="display: flex; align-items: center;">
            <TimeInput
              v-model="event.intervention.rdv_min_start_time"
              @update:modelValue="onRdvMinStartTimeSelected" />
          </div>
        </div>

        <div class="col-md form-group">
          <label class="form-label">Le technicien peut arriver jusqu'à</label>
          <div style="display: flex; align-items: center;">
            <TimeInput
              v-model="event.intervention.rdv_max_start_time"
              @update:modelValue="onRdvMaxStartTimeSelected" />
          </div>
        </div>
      </div>

      <div v-if="event.intervention" class="form-group">
        <label class="form-label">Durée prévue de l'intervention</label>
        <div class="input-group">
          <input
            v-model="duration"
            type="number"
            min="0"
            class="form-control">
          <div class="input-group-append">
            min
          </div>

          <button
            v-if="customDuration != null && customDuration != event.intervention.duration"
            class="btn-primary"
            style="margin-left: 5px;"
            :class="{ 'loading': savingCustomDuration }"
            :disabled="savingCustomDuration"
            @click="saveCustomDuration">
            Enregistrer
          </button>
        </div>
      </div>
    </div>

    <template #buttons>
      <button
        class="btn-secondary"
        @click="hide">
        Ok
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "CalendarEventModal",
  components: {
    Modal,
    TimeInput,
    RdvDatePicker,
    Loader,
  },
  data() {
    return {
      event: null,
      customDuration: null,
      savingCustomDuration: false,
      synchronizingWithGoogleCalendar: false,
    };
  },
  computed: {
    duration: {
      get() {
        if (this.customDuration != null) {
          return this.customDuration;
        }
        return this.event.intervention.duration;
      },
      set(value) {
        this.customDuration = value;
      },
    },
  },
  methods: {
    show(event) {
      this.event = event;
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    // Rdv date and time

    onRdvDateSelected(date) {
      this.$services.intervention.makeAppointment(
        this.event.intervention.id,
        date,
      ).then((intervention) => {
        this.event.intervention.rdv_date = intervention.rdv_date;
        this.syncInterventionWithGoogleCalendar();
      });
    },

    syncInterventionWithGoogleCalendar() {
      this.synchronizingWithGoogleCalendar = true;

      this.$services.intervention.syncWithGoogleCalendar(this.event.intervention.id)
        .finally(() => {
          this.synchronizingWithGoogleCalendar = false;
        });
    },

    onRdvMinStartTimeSelected(time) {
      this.$services.intervention.patch(
        this.event.intervention.id,
        {
          rdv_min_start_time: time,
        },
      ).then((response) => {
        this.event.intervention.rdv_min_start_time = response.data.rdv_min_start_time;
      });
    },

    onRdvMaxStartTimeSelected(time) {
      this.$services.intervention.patch(
        this.event.intervention.id,
        {
          rdv_max_start_time: time,
        },
      ).then((response) => {
        this.event.intervention.rdv_max_start_time = response.data.rdv_max_start_time;
      });
    },

    saveCustomDuration() {
      this.savingCustomDuration = true;
      this.$services.intervention.patch(
        this.event.intervention.id,
        {
          custom_duration: this.customDuration,
        },
      ).then((response) => {
        this.customDuration = null;
        this.event.intervention.duration = response.data.custom_duration;
      }).finally(() => {
        this.savingCustomDuration = false;
      });
    },
  },
};
</script>

<style lang="scss" scroped>

   
</style>