<template>
  <Modal ref="modal">
    <div class="form-group">
      <label class="form-label">Date de rendez-vous</label>
      <RdvDatePicker
        v-model="rdvDate" />
    </div>

    <div class="form-group">
      <label class="form-label">Fourchette d'arrivée du technicien</label>
      <div class="flow-spaced-container" style="align-items: center;">
        <TimeInput
          v-model="rdvMinStartTime" />

        <TimeInput
          v-model="rdvMaxStartTime" />
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Durée estimée</label>
      <div class="input-group">
        <input
          v-model="duration"
          type="number"
          min="0"
          class="form-control">
        <div class="input-group-append">
          min
        </div>
      </div>
    </div>

    <div v-if="conflictingEvent" class="highlighted red">
      Un événement est déjà prévu sur ce créneau pour {{ conflictingEvent.user.last_name }} {{ conflictingEvent.user.first_name }} :
      <br>
      <strong>{{ conflictingEvent.summary }}</strong>
      <br>
      <small>De {{ $filters.dateStringToHumanizedDate(conflictingEvent.start_date) }} {{ conflictingEvent.start_time.substring(0, 5) }} à {{ $filters.dateStringToHumanizedDate(conflictingEvent.end_date) }} {{ conflictingEvent.end_time.substring(0, 5) }}</small>
      <br>
      <small>{{ conflictingEvent.location }}</small>
    </div>

    <template #buttons>
      <div class="flow-spaced-container">
        <div
          v-if="synchronizingWithGoogleCalendar"
          style="display: flex; align-items: center; color: blue;">
          <div style="margin-right: 5px;">
            <Loader />
          </div>
          Synchronisation Google Agenda en cours...
        </div>

        <button
          v-else
          class="btn-secondary"
          :disabled="loading"
          @click="hide">
          Annuler
        </button>
      
        <button
          v-if="!synchronizingWithGoogleCalendar && conflictingEvent == null"
          class="btn-primary"
          :class="{ 'loading': loading }"
          :disabled="loading || !canSubmit"
          @click="submit">
          Enregistrer
        </button>

        <button
          v-if="!synchronizingWithGoogleCalendar && conflictingEvent != null"
          class="btn-primary"
          :class="{ 'loading': loading }"
          :disabled="loading"
          @click="forceSubmit">
          Enregistrer quand même
        </button>

        <button
          v-if="synchronizingWithGoogleCalendar"
          class="btn-primary"
          @click="hide">
          Terminer
        </button>
      </div>
    </template>
  </Modal>

  <ConfirmModal
    ref="confirmCloseCallTaskModal"
    @confirm="closeCallTasks" />
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import RdvDatePicker from "@/components/inputs/RdvDatePicker.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";
import Loader from "@/components/Loader.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  name: "EditInterventionRdvModal",
  components: {
    Modal,
    RdvDatePicker,
    TimeInput,
    Loader,
    ConfirmModal,
  },

  emits: ["change"],
  data() {
    return {
      intervention: null,
      rdvDate: null,
      loading: false,
      synchronizingWithGoogleCalendar: false,
      rdvMinStartTime: null,
      rdvMaxStartTime: null,
      conflictingEvent: null,
      customDuration: null,
    };
  },
  computed: {
    duration: {
      get() {
        if (this.customDuration !== null) {
          return this.customDuration;
        } else {
          return this.intervention.duration;
        }
      },
      set(value) {
        this.customDuration = value;
      },
    },
    canSubmit() {
      return this.rdvDate !== null;
    },
  },
  methods: {
    show(intervention) {
      this.reset();
      this.load(intervention);
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.reset();
    },
    reset() {
      this.intervention = null;
      this.rdvDate = null;
      this.rdvMinStartTime = null;
      this.rdvMaxStartTime = null;
      this.customDuration = null;
      this.loading = false;
      this.synchronizingWithGoogleCalendar = false;
      this.conflictingEvent = null;
    },
    load(intervention) {
      this.intervention = intervention;
      this.rdvDate = intervention.rdv_date;
      this.rdvMinStartTime = intervention.rdv_min_start_time;
      this.rdvMaxStartTime = intervention.rdv_max_start_time;
      this.customDuration = intervention.custom_duration;
    },
    submit(force=false) {
      this.loading = true;
      this.conflictingEvent = null;

      this.$services.intervention.makeAppointment(
        this.intervention.id,
        this.rdvDate,
        null,
        this.rdvMinStartTime,
        this.rdvMaxStartTime,
        this.customDuration,
        force,
      ).then((intervention) => {
        this.load(intervention);
        this.$emit("change", intervention);
        this.syncInterventionWithGoogleCalendar();

        if (intervention.tasks.findIndex((task) => task.action === "call") !== -1) {
          this.$refs.confirmCloseCallTaskModal.show("Voulez-vous cloturer les taches 'Appeler' sur cette intervention ?");
        }

      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.code === "conflict") {
          this.conflictingEvent = error.response.data.conflicting_event;
        } else {
          throw error;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    forceSubmit() {
      this.submit(true);
    },
    syncInterventionWithGoogleCalendar() {
      this.synchronizingWithGoogleCalendar = true;
      this.$services.intervention.syncWithGoogleCalendar(this.intervention.id)
        .then(() => {
          this.hide();
        })
        .finally(() => {
          this.synchronizingWithGoogleCalendar = false;
        });
    },

    closeCallTasks() {
      this.intervention.tasks.forEach((task) => {
        if (task.action === "call" && task.status === "todo") {
          this.$services.task.toggleStatus(task.id)
            .then((response) => {
              task.status = response.data.status;
            });
        }
      });
      this.$refs.confirmCloseCallTaskModal.hide();
    },
    
  },
};

</script>

<style lang="scss" scoped>


</style>