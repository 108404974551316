<template>
  <Modal ref="modal">
    <div class="horizontal-spaced-container align-bottom">
      <div>
        <label class="form-label">Choix du créneau</label>
        <div class="input-group">
          <div class="input-group-prepend">
            Horaire
          </div>
          <select
            v-model="selectedSubSlot"
            class="form-select"
            style="max-width: 200px; border-top-right-radius: 0; border-bottom-right-radius: 0;">
            <option
              v-for="(subSlot, subSlotIndex) in subSlots"
              :key="subSlotIndex"
              :value="subSlot">
              {{ dateToHumanizedSubSlot(subSlot) }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <label class="form-label">Le technicien peut arriver à partir de</label>
        <div style="display: flex; align-items: center;">
          <TimeInput
            v-model="rdvMinStartTime" />
        </div>
      </div>

      <div>
        <label class="form-label">Le technicien peut arriver jusqu'à</label>
        <div style="display: flex; align-items: center;">
          <TimeInput
            v-model="rdvMaxStartTime" />
        </div>
      </div>
    </div>

    <template #buttons>
      <div class="horizontal-spaced-container">
        <button
          class="btn-secondary"
          @click="hide">
          Annuler
        </button>
        
        <button
          class="btn-primary"
          :disabled="selectedSubSlot == null || submitting"
          :class="{ 'loading': submitting }"
          @click="submit">
          Valider
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>

import Modal from "@/components/modals/Modal.vue";
import TimeInput from "@/components/baseComponents/TimeInput.vue";

export default {
  name: "SelectSlotModal",
  components: {
    Modal,
    TimeInput,
  },
  data() {
    return {
      slotProp: null,
      intervention: null,
      selectedSubSlot: null,
      rdvMinStartTime: null,
      rdvMaxStartTime: null,
      durationTripFromPreviousPlanningEvent: null,
      durationTripToNextPlanningEvent: null,
      submitting: false,
    };
  },
  computed: {
    subSlots() {
      if (this.slotProp == null) {
        return [];
      }

      if (this.slotProp.previous_planning_event == null && this.slotProp.next_planning_event == null) {
        return [];
      }

      var subSlots = [];

      var startDate;
      var endDate;

      if (this.slotProp.previous_planning_event != null) {
        startDate = new Date(this.slotProp.previous_planning_event.end_date + "T" + this.slotProp.previous_planning_event.end_time);

      } else if (this.slotProp.next_planning_event != null) {
        startDate = new Date(new Date(this.slotProp.next_planning_event.start_date + "T" + this.slotProp.next_planning_event.start_time).getTime() - 4*60*60000);

      } else {
        throw new Error("Should not be reachable");
      }

      // More startDate forward to the next quarter
      
      var min = startDate.getMinutes();
      var minToQuarter = 15 - (min % 15);
      startDate.setMinutes(min + minToQuarter);

      if (this.slotProp.next_planning_event != null) {
        endDate = new Date(this.slotProp.next_planning_event.start_date + "T" + this.slotProp.next_planning_event.start_time);
      } else {
        endDate = new Date(startDate.getTime() + 4*60*60000);
      }

      var currentSubSlot = new Date(startDate);
      
      while(currentSubSlot <= endDate) {
        subSlots.push(new Date(currentSubSlot));
        currentSubSlot = new Date(currentSubSlot.getTime() + 15*60000);
      }

      return subSlots;
    },
    advisedStartTime() {

      if (this.slotProp == null) {
        return null;
      }

      if (this.intervention == null) {
        return null;
      }

      if (this.slotProp.previous_planning_event != null) {

        if (this.durationTripFromPreviousPlanningEvent == null) {
          return null;
        }

        var endOfThePreviousEvent = new Date(this.slotProp.previous_planning_event.end_date + "T" + this.slotProp.previous_planning_event.end_time);

        return new Date(endOfThePreviousEvent.getTime() + (this.durationTripFromPreviousPlanningEvent * 1000));

      } else if (this.slotProp.next_planning_event != null) {
        if (this.durationTripToNextPlanningEvent == null) {
          return null;
        }

        return new Date(this.advisedEndTime.getTime() - (this.intervention.duration * 1000 * 60));

      } else {
        return null;
      }
    },
    advisedEndTime() {

      if (this.intervention == null) {
        return null;
      }

      if (this.slotProp.previous_planning_event != null) {
        if (this.durationTripFromPreviousPlanningEvent == null) {
          return null;
        }

        if (this.advisedStartTime == null) {
          return null;
        }

        return new Date(this.advisedStartTime.getTime() + (this.intervention.duration * 1000 * 60));

      } else if (this.slotProp.next_planning_event != null) {
        if (this.durationTripToNextPlanningEvent == null) {
          return null;
        }

        const startOfTheNextEvent = new Date(this.slotProp.next_planning_event.start_date + "T" + this.slotProp.next_planning_event.start_time);

        return new Date(startOfTheNextEvent.getTime() - (this.durationTripToNextPlanningEvent * 1000));

      } else {
        return null;
      }


    },
  },
  methods: {
    reset() {
      this.selectedSubSlot = null;
      this.rdvMinStartTime = null;
      this.rdvMaxStartTime = null;
      this.intervention = null;
      this.durationTripFromPreviousPlanningEvent = null;
      this.durationTripToNextPlanningEvent = null;
      this.submitting = false;
    },
    show(slotProp, intervention) {
      this.reset();
      this.slotProp = slotProp;
      this.intervention = intervention;
      this.fetchTripDuration();
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.reset();
    },
    dateToHumanizedSubSlot(date) {
      const dateStr = date.toLocaleDateString("fr-FR", { weekday: "short", month: "short", day: "numeric" });
      const timeStr = date.toLocaleTimeString("fr-FR", {hour: "2-digit", minute:"2-digit"});
      return `${dateStr} à ${timeStr}`;
    },
    submit() {
      this.submitting = true;

      this.$services.makeAppointment(
        this.intervention.id,
        this.$filters.dateToISOString(this.selectedSubSlot),
        this.slotProp.previous_planning_event.user.id,
        this.rdvMinStartTime,
        this.rdvMaxStartTime,
      ).then((intervention) => {
        this.$router.push({ name: "intervention", params: { id: intervention.id } });
      }).finally(() => {
        this.submitting = false;
      });
    },
    fetchTripDuration() {
      this.fetchingTripDuration = true;

      const promises = [];

      if (this.slotProp.previous_planning_event) {
        promises.push(
          this.$services.intervention.tripDurationFromAddress(
            this.intervention.id,
            this.slotProp.previous_planning_event.latitude,
            this.slotProp.previous_planning_event.longitude,
          ).then((response) => {
            this.durationTripFromPreviousPlanningEvent = response.data;
          }),
        );
      }

      if (this.slotProp.next_planning_event) {
        promises.push(
          this.$services.intervention.tripDurationToAddress(
            this.intervention.id,
            this.slotProp.next_planning_event.latitude,
            this.slotProp.next_planning_event.longitude,
          ).then((response) => {
            this.durationTripToNextPlanningEvent = response.data;
          }),
        );
      }

      Promise.all(promises)
        .then(() => {
          for (const subSlot of this.subSlots) {
            if (subSlot.getTime() > this.advisedStartTime.getTime()) {
              this.selectedSubSlot = subSlot;
              break;
            }
          }
        })
        .finally(() => {
          this.fetchingTripDuration = false;
        });

    },
  },
};

</script>

<style lang="scss" scoped>


</style>