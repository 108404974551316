<template>
  <LoaderView v-if="schema == null" />
  <div v-else>
    <div class="flow-spaced-container">
      <FormField
        :schema="schema.title"
        style="max-width: 100px;">
        <select
          v-model="internalValue.title"
          class="form-select"
          @change="updateModelValue">
          <option value="mme">
            Madame
          </option>
          <option value="mr">
            Monsieur
          </option>
          <option value="mx">
            Neutre
          </option>
        </select>
      </FormField>

      <FormField
        :schema="schema.last_name"
        style="min-width: 100px;"
        @change="updateModelValue">
        <input v-model="internalValue.last_name" type="text" class="form-control">
      </FormField>

      <FormField
        :schema="schema.first_name"
        style="min-width: 100px;"
        @change="updateModelValue">
        <input v-model="internalValue.first_name" type="text" class="form-control">
      </FormField>
    </div>

    <div class="flow-spaced-container">
      <FormField
        :schema="schema.phone_number"
        style="min-width: 200px;"
        @change="updateModelValue">
        <PhoneNumber v-model="internalValue.phone_number" />
      </FormField>

      <FormField
        :schema="schema.email"
        style="min-width: 100px;"
        @change="updateModelValue">
        <input v-model="internalValue.email" type="text" class="form-control">
      </FormField>
    </div>

    <div class="flow-spaced-container">
      <FormField
        :schema="schema.language"
        style="min-width: 100px;"
        @change="updateModelValue">
        <select v-model="internalValue.language" class="form-select">
          <option value="fr">
            Français
          </option>
          <option value="nl">
            Néerlandais
          </option>
        </select>
      </FormField>
    </div>
  </div>
</template>

<script>

import FormField from "@/components/forms/FormField.vue";
import LoaderView from "@/components/LoaderView.vue";
import PhoneNumber from "@/components/vueComponents/input/PhoneNumber.vue";

export default {
  name: "PersonForm",
  components: {
    FormField,
    LoaderView,
    PhoneNumber,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      schema: null,
      internalValue: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler(value) {
        this.internalValue = value;
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchSchema();
  },
  methods: {
    fetchSchema() {
      return this.$services.person.schema()
        .then((response) => {
          this.schema = response.data;
        });
    },
    updateModelValue() {
      this.$emit("update:modelValue", this.internalValue);
    },
  },
};
</script>

<style>

</style>